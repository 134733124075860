import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step9 extends React.Component {

  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.prevStep()
  }
  
  render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">Flip 3 - Spasms</h1>
        
          <Form.Field>
            <label className="labelwide">Sustained Esophageal Contraction <span className="sublabel">(SEC present in 2 contractions)</span></label>
            <input
              type='checkbox'
              className="checkinput"
              onChange={this.props.handleChange('impedanceplanimetrysec')}
              defaultChecked={values.impedanceplanimetrysec}
            />
          </Form.Field>
          <Form.Field>
            <label>SEC &gt; 20 seconds</label>
            <input
              type='checkbox'
              className="checkinput"
              onChange={this.props.handleChange('secgt20')}
              defaultChecked={values.secgt20}
            />
          </Form.Field>     
      
        <div>&nbsp;</div>
        <Form.Field>
          <label className="labelwide">Sustained Occluding Contraction <span className="sublabel">(SOC present in 2 contractions)</span></label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('impedanceplanimetrysoc')}
          defaultChecked={values.impedanceplanimetrysoc}
          />
        </Form.Field>
        <Form.Field>
          <label>SOC &gt; 20 seconds</label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('socgt20')}
          defaultChecked={values.socgt20}
          />
        </Form.Field>    

        <div>&nbsp;</div>
        <Form.Field>
          <label className="labelwide">Hypercontractile Contractions <span className="sublabel">(Antegrade &gt; 20 seconds)</span></label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('impedanceplanimetryhc')}
          defaultChecked={values.impedanceplanimetryhc}
          />
        </Form.Field>     
        <Form.Field>
          <label>LES Lift <span className="sublabel"> (&gt;= 2 contractions)</span></label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.leslift}
          onChange={this.props.handleChange('leslift')}
          />
        </Form.Field>
        

        <div>&nbsp;</div>

        <div className="forwardbackbuttons">
            <Button onClick={this.goBack}>Go Back</Button>
            <Button onClick={this.saveAndContinue}>Finish and Score</Button>
      </div>
      </Form>
    )
  }
}
export default Step9