import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step7 extends React.Component {

  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.prevStep()
  }
  
  render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">FLIP 1</h1>
        
        <Form.Field>
          <label className="labelwide">Distensibility Index of EGJ (DI-EGJ)</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('impedanceplanimetrydi')}
          defaultValue={values.impedanceplanimetrydi}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">Diameter</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('diameter')}
          defaultValue={values.diameter}
          />
        </Form.Field>
       
        <Form.Field>
          <label className="labelwide">LES Non Relaxing at Low Volumes</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.lesnonrelaxingatlowvolume}
          onChange={this.props.handleChange('lesnonrelaxingatlowvolume')}
          />
        </Form.Field>
        <Form.Field>
          <label>Poor Relaxation</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.poorrelaxation}
          onChange={this.props.handleChange('poorrelaxation')}
          />
        </Form.Field>

        <div>&nbsp;</div>

        <div className="forwardbackbuttons">
            <Button onClick={this.goBack}>Go Back</Button>
            <Button onClick={this.saveAndContinue}>Continue</Button>
        </div>
      </Form>
    )
  }
}
export default Step7