import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step6 extends React.Component {

  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.prevStep()
  }
  
  render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">Manometry</h1>

        <Form.Field>
          <label>IRP</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometryirp')}
          defaultValue={values.manometryirp}
          />
        </Form.Field>
        <Form.Field>
          <label>% Failed</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercentfailed')}
          defaultValue={values.manometrypercentfailed}
          />
        </Form.Field>
        <Form.Field>
          <label>% Weak</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercentweak')}
          defaultValue={values.manometrypercentweak}
          />
        </Form.Field>
        <Form.Field>
          <label>% Hypercontractile</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercenthypercontractile')}
          defaultValue={values.manometrypercenthypercontractile}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">% Panesophageal Pressurization</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercentpep')}
          defaultValue={values.manometrypercentpep}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">% Premature Contraction</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercentprematuredl')}
          defaultValue={values.manometrypercentprematuredl}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">% Fragmented Swallows</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercentfragmentedswallows')}
          defaultValue={values.manometrypercentfragmentedswallows}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">% Distal Pressurization</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercentdistalpressurization')}
          defaultValue={values.manometrypercentdistalpressurization}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">% Mid Pressurization</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('manometrypercentmidpressurization')}
          defaultValue={values.manometrypercentmidpressurization}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">% Simultaneous Contractions</label>
          <input
          className="numberinput"
          defaultValue={values.simultaneouscontraction}
          onChange={this.props.handleChange('simultaneouscontraction')}
          />
        </Form.Field>
        <Form.Field>
          <label>Multiple Peaks </label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.multiplepeakedswallows}
          onChange={this.props.handleChange('multiplepeakedswallows')}
          />
        </Form.Field>
        <span className="sublabel">(present in 2 or more swallows)</span>
        <Form.Field>
          <label>Pseudorelaxation </label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.pseudorelaxation}
          onChange={this.props.handleChange('pseudorelaxation')}
          />
        </Form.Field>
        <span className="sublabel">(present in 2 or more swallows)</span>

        <Form.Field>
          <label className="labelwide">IRP During Upright Swallows</label>
          <input
          className="numberinput"
          defaultValue={values.irpduringuprightswallows}
          onChange={this.props.handleChange('irpduringuprightswallows')}
          />
        </Form.Field>
        
        <Form.Field>
          <label>Peristalsis Characterization</label>
          <select value={values.manometryabnormalswallows} 
            className="selectinput"
            onChange={this.props.handleChange('manometryabnormalswallows')}>
            <option value="failed">100% Failed</option>
            <option value="abnormal">Peristalsis 100% Abnormal</option>
            <option value="somenormal" default>Some Intact Peristalsis</option>
          </select>
        </Form.Field>
        <div>&nbsp;</div>

        <div className="forwardbackbuttons">
          <Button onClick={this.goBack}>Go Back</Button>
          <Button onClick={this.saveAndContinue}>Continue</Button>
        </div>
      </Form>
    )
  }
}
export default Step6