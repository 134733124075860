import React, { Component } from 'react'
import ScoreChart from './ScoreChart.js'
import { Button } from 'semantic-ui-react';

import { CSVReader } from 'react-papaparse'
 
import Score from './score.js'

class UploadToScore extends Component {

  state = {
      data: null
  }
  
  constructor() {
    super();
    this.baseState = this.state;
  }
  
  startOver = (e) => {
    //e.preventDefault()
    this.setState(this.baseState)
  }
  
  handleOnDrop = (data) => {
//    console.log('---------------------------')
//    console.log(data)
//    console.log('---------------------------')
    this.setState( {
        data: data
    });
  }
 
  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }
 
  handleOnRemoveFile = (data) => {
//    console.log('---------------------------')
//    console.log(data)
//    console.log('---------------------------')
  }
  
  downloadScores = (e) => {
    this.scoreCsv(this.getScores(this.state.data));
  }

  getScores(data){
    //console.log(data)
    let scores = []
    data.forEach(element => {
        let values = element.data
        let score = new Score(values)
        scores.push(score)
    });
    return scores
  }

  scoreCsv = (scores) => {
    const rows = ["identifier","d2_x","d2_y","d2_z","d2_d","d2_a1","d2_l1","d2_l2","d2_a2","d2_s","d2_diagnosis", "pain_mod",
                  "chicago4","d1_x","d1_y","d1_d","d1_a1","d1_l1","d1_l2","d1_a2","d1_s","chicago3"];
    let csvContent = "data:text/csv;charset=utf-8,"
    csvContent += rows.join(",") + "\r\n";
    scores.forEach(function(score){
      let row = [score.getId(), score.dallas2CalculateX(), score.dallas2CalculateY(), score.dallas2CalculateZ(),
                 score.dallas2CalculateD(), score.dallas2CalculateA1(), score.dallas2CalculateL1(),
                 score.dallas2CalculateL2(), score.dallas2CalculateA2(), score.dallas2CalculateS(),
                 "\""+score.dallas2Classification()+"\"", score.dallas2PainModifier(), score.chicagoClassification4(),
                 score.calculateX(), score.calculateY(), score.calculateD(), score.calculateA1(),
                 score.calculateL1(), score.calculateL2(), score.calculateA2(), score.calculateS(),
                 score.chicagoClassification()
      ].join(",");
      csvContent += row + "\r\n";
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "dallas.esophaguide.data.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  render() {
    let data = null
    if (this.state.data){
        data = this.state.data
    }

    if (data == null){
        return (
            <CSVReader
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                config={{
                    "header": true
                }}
                addRemoveButton
            onRemoveFile={this.handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        )   
    } else {
        const scores = this.getScores(data)
        return (
            <div>
                <h3>Scores</h3>
                <div>
                  <span>Hover over a point for the detail below.</span>
                </div>
                <div id="scorechart">
                  <ScoreChart
                    scores={scores}
                    size={[600,500]}>
                  </ScoreChart>
                </div>
                <Button onClick={this.startOver}>Go To The Beginning</Button>
                <Button onClick={this.downloadScores}>Download Score CSV</Button>
            </div>
        )
    }

  }
}
export default UploadToScore;