import React from 'react';
import { Form, Button } from 'semantic-ui-react';


class Step2 extends React.Component {
  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
}

  goBack = (e) => {
  e.preventDefault()
  this.props.prevStep()
}

render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">Symptoms</h1>

        <Form.Field>
            <label>Trouble Swallowing (Dysphagia)</label>
            <select value={values.dysphagia} 
              className="selectinput"
              onChange={this.props.handleChange('dysphagia')}>
            <option value="none">None</option>
            <option value="occassional">Occassional</option>
            <option value="daily">Daily</option>
            <option value="every">Every Meal</option>
          </select>      
        </Form.Field>
    
        <Form.Field>
          <label>Regurgitation</label>
          <select value={values.regurgitation} 
            className="selectinput"
            onChange={this.props.handleChange('regurgitation')}>
            <option value="none">None</option>
            <option value="occassional">Occassional</option>
            <option value="daily">Daily</option>
            <option value="every">Every Meal</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Chest Pain</label>
          <select value={values.chestpain} 
            className="selectinput"
            onChange={this.props.handleChange('chestpain')}>
            <option value="none">None</option>
            <option value="occassional">Occassional</option>
            <option value="daily">Daily</option>
            <option value="multipleperday">Multiple Times Per Day</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Weight Loss</label>
          <select value={values.weightloss} 
            className="selectinput"
            onChange={this.props.handleChange('weightloss')}>
            <option value="none">0</option>
            <option value="to11">0-11 Lbs</option>
            <option value="12to22">11-22 Lbs</option>
            <option value="over22">22+ Lbs</option>
          </select>
        </Form.Field>

        <div>&nbsp;</div>

      <div className="forwardbackbuttons">
        <Button onClick={this.goBack}>Go Back</Button>
        <Button onClick={this.saveAndContinue}>Continue </Button>
      </div>
      </Form>
    )
}
}
export default Step2


