import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step8 extends React.Component {

  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.prevStep()
  }
  
  render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">Flip 2 - Contractions</h1>
    
        <Form.Field>
          <label className="labelwide">Absent Contractions</label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('impedanceplanimetryabsent')}
          defaultChecked={values.impedanceplanimetryabsent}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">Diminished Antegrade (DAC)</label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('impedanceplanimetryantegradediminished')}
          defaultChecked={values.impedanceplanimetryantegradediminished}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">Repetitive Antegrade (RAC)</label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('impedanceplanimetryrac')}
          defaultChecked={values.impedanceplanimetryrac}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">Repetitive Retrograde (RRC)</label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('impedanceplanimetryrrc')}
          defaultChecked={values.impedanceplanimetryrrc}
          />
        </Form.Field>
        <Form.Field>
          <label className="labelwide">Disorganized Diminished (DDC)</label>
          <input
          type='checkbox'
          className="checkinput"
          onChange={this.props.handleChange('impedanceplanimetryddc')}
          defaultChecked={values.impedanceplanimetryddc}
          />
        </Form.Field>
        <div>&nbsp;</div>

        <div className="forwardbackbuttons">
            <Button onClick={this.goBack}>Go Back</Button>
            <Button onClick={this.saveAndContinue}>Continue</Button>
      </div>
      </Form>
    )
  }
}
export default Step8