class Colors {
    // https://observablehq.com/@d3/color-schemes
    colors = {
        // Spasms
        "6": "#fccac8",         // pink
        // ["#fff7f3","#fde2df","#fccac8","#fbabb8","#f880aa","#ea519d","#cc238e","#a2057e","#750175","#49006a"]
        "5": "#BB200F",         // red
        "7": "#E66013",         // orange
        // Spastic achalasia variants
        "18": "#CA33FF",        // bright purple
        "17": "#3e4989",        // violet
        //["#440154","#482878","#3e4989","#31688e","#26828e","#1f9e89","#35b779","#6ece58","#b5de2b","#fde725"]
        "15": "#52238d",        // purple
        // ["#fcfbfd","#f0eef6","#dedded","#c6c6e1","#abaad1","#918dc2","#796eb2","#65489f","#52238d","#3f007d"]
        "16": "#ea519d",        // mauve (purple-ish)
        // ["#fff7f3","#fde2df","#fccac8","#fbabb8","#f880aa","#ea519d","#cc238e","#a2057e","#750175","#49006a"]
        // Normal or ineffective
        "4": "#000000",         // black
        "3": "#582E00",         // brown
        "2": "#858687",         // gray
        // yellow: absent contractility
        "1": "#EAB528",         // yellow
        // green:  classic achalasia
        "11": "#04631c",        // moss green
        "10": "#86C741",         // lime green
        // blue:  EGJ outflow obstruction
        "12": "#001E56",        // navy blue
        "13": "#0F84BD",        // royal blue
    }

    getColor(category){
        if (category !== "" && this.colors[category]){
            return this.colors[category]
        } else {
            return "gold"
        }
    }

}

export default Colors;