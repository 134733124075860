import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step4 extends React.Component {

  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.prevStep()
  }
  
  render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">Barium Swallow Findings</h1>
        <Form.Field>
          <label>Narrow GEJ</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.narrowgej}
          onChange={this.props.handleChange('narrowgej')}
          />
        </Form.Field>
        <Form.Field>
          <label>Tablet Lodged At GEJ</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.tabletlodgedatgej}
          onChange={this.props.handleChange('tabletlodgedatgej')}
          />
        </Form.Field>
        <Form.Field>
          <label>Dilated Esophagus</label>
          <select value={values.dilated} 
            onChange={this.props.handleChange('dilated')}>
            <option value="none">None</option>
            <option value="mild">Mild</option>
            <option value="moderate">Moderate</option>
            <option value="severe">Severe</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label className="labelwide">Sigmoidization of Esophagus</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.sigmoidizationofesophagus}
          onChange={this.props.handleChange('sigmoidizationofesophagus')}
          />
        </Form.Field>
        <Form.Field>
          <label>Sump Effect</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.sumpeffect}
          onChange={this.props.handleChange('sumpeffect')}
          />
        </Form.Field>
        <Form.Field>
          <label>Peristalsis</label>
          <select value={values.bariumesophagramprimaryperistalsis} 
            className="selectinput"
            onChange={this.props.handleChange('bariumesophagramprimaryperistalsis')}>
            <option value="absent">Absent</option>
            <option value="weak">Weak or Incomplete</option>
            <option value="intact">Intact</option>
            <option value="disordered">Disordered</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Delayed Clearance of Contract</label>
          <input
          className="checkinput"
          type='checkbox'
          defaultChecked={values.bariumesophagramdelay}
          onChange={this.props.handleChange('bariumesophagramdelay')}
          />
        </Form.Field>
        <Form.Field>
          <label>Proximal Escape or Retrograde Flow</label>
          <input
          className="checkinput"
          type='checkbox'
          defaultChecked={values.bariumesophagramretrograde}
          onChange={this.props.handleChange('bariumesophagramretrograde')}
          />
        </Form.Field>

        <Form.Field>
          <label>Corkscrew Esophagus</label>
          <input
          className="checkinput"
          type='checkbox'
          defaultChecked={values.bariumesophagramcorkscrewesophagus}
          onChange={this.props.handleChange('bariumesophagramcorkscrewesophagus')}
          />
        </Form.Field>
        <div>&nbsp;</div>

        <div className="forwardbackbuttons">
          <Button onClick={this.goBack}>Go Back</Button>
          <Button onClick={this.saveAndContinue}>Continue </Button>
        </div>
      </Form>
    )
  }
}
export default Step4