import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step5 extends React.Component {

  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.prevStep()
  }
  
  render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">Endoscopy Findings</h1>

        <Form.Field>
          <label>Hiatal Hernia</label>
          <select value={values.hiatalhernia} 
            className="selectinput"
            onChange={this.props.handleChange('hiatalhernia')}>
            <option value="none">None</option>
            <option value="mild">Mild</option>
            <option value="moderate">Moderate</option>
            <option value="severe">Severe</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Hill Grade Valve</label>
          <select value={values.hillgradevalve} 
            className="selectinput"
            onChange={this.props.handleChange('hillgradevalve')}>
            <option value="I">I</option>
            <option value="II">II</option>
            <option value="III">III</option>
            <option value="IV">IV</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Dilated esophagus</label>
          <select value={values.egddilated} 
            className="selectinput"
            onChange={this.props.handleChange('egddilated')}>
            <option value="none">None</option>
            <option value="mild">Mild</option>
            <option value="moderate">Moderate</option>
            <option value="severe">Severe</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Retention of Contents in Esophagus</label>
          <select value={values.egdretention} 
            className="selectinput"
            onChange={this.props.handleChange('egdretention')}>
            <option value="none">None</option>
            <option value="mild">Frothy Secretions</option>
            <option value="moderate">Liquid Only</option>
            <option value="severe">Solids and Liquids</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Appearance of LES</label>
          <select value={values.appearanceofles} 
            className="selectinput"
            onChange={this.props.handleChange('appearanceofles')}>
            <option value="patulous">Patulous</option>
            <option value="opensandcloses">Opens and Closes</option>
            <option value="minimallyopens">Minimally Opens</option>
            <option value="tight">Tight or Hypertensive</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Resistance at LES</label>
          <select value={values.resistanceatles} 
            className="selectinput"
            onChange={this.props.handleChange('resistanceatles')}>
            <option value="none">None</option>
            <option value="mild">Mild</option>
            <option value="moderate">Moderate</option>
            <option value="severe">Severe ("Pop")</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Contractions</label>
          <select value={values.contractions} 
            className="selectinput"
            onChange={this.props.handleChange('contractions')}>
            <option value="absent">Absent</option>
            <option value="periodic">Periodic</option>
            <option value="simultaneous">Simultaneous</option>
            <option value="spastic">Spastic</option>
            <option value="buttressing">Buttressing</option>
          </select>
        </Form.Field>
      
        <div>&nbsp;</div>

        <div className="forwardbackbuttons">
          <Button onClick={this.goBack}>Go Back</Button>
          <Button onClick={this.saveAndContinue}>Continue </Button>
        </div>
      </Form>
    )
  }
}
export default Step5