import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step3 extends React.Component {

  saveAndContinue = (e) => {
    e.preventDefault()
    this.props.nextStep()
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.prevStep()
  }
  
  render(){
    const { values } = this.props;
    return(
      <Form >
        <h1 className="ui centered">Patient Characteristics</h1>

        <Form.Field>
          <label>BMI</label>
          <input
          className="numberinput"
          onChange={this.props.handleChange('bmi')}
          defaultValue={values.bmi}
          />
        </Form.Field>
        <Form.Field>
          <label>Narcotics Use</label>
          <input
            type='checkbox'
            className="checkinput"
            defaultChecked={values.narcotics}
            onChange={this.props.handleChange('narcotics')}
          />
        </Form.Field>

        <div>&nbsp;</div>
        <h3 className="left">Prior Surgery</h3>
        <Form.Field>
          <label>Heller</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.priorheller}
          onChange={this.props.handleChange('priorheller')}
          />
        </Form.Field>
        <Form.Field>
          <label>POEM</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.priorpoem}
          onChange={this.props.handleChange('priorpoem')}
          />
        </Form.Field>
        <Form.Field>
          <label>Dor Fundoplication</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.dorfundoplication}
          onChange={this.props.handleChange('dorfundoplication')}
          />
        </Form.Field>
        <Form.Field>
          <label>Toupet Fundoplication</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.toupetfundoplication}
          onChange={this.props.handleChange('toupetfundoplication')}
          />
        </Form.Field>
        <Form.Field>
          <label>Nissen Fundoplication</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.nissenfundoplication}
          onChange={this.props.handleChange('nissenfundoplication')}
          />
        </Form.Field>
        <Form.Field>
          <label>Lap Band</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.lapband}
          onChange={this.props.handleChange('lapband')}
          />
        </Form.Field>
        <Form.Field>
          <label>Gastric Sleeve</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.gastricsleeve}
          onChange={this.props.handleChange('gastricsleeve')}
          />
        </Form.Field>
        <Form.Field>
          <label>Gastric Bypass</label>
          <input
          type='checkbox'
          className="checkinput"
          defaultChecked={values.gastricbypass}
          onChange={this.props.handleChange('gastricbypass')}
          />
        </Form.Field>
        <div>&nbsp;</div>

        <div className="forwardbackbuttons">
          <Button onClick={this.goBack}>Go Back</Button>
          <Button onClick={this.saveAndContinue}>Continue </Button>
        </div>

      </Form>
    )
  }
}
export default Step3