import React from 'react';
import { Form, Button } from 'semantic-ui-react';

class Step1 extends React.Component {
    
    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    render(){
        const { values } = this.props;
        return(
            <Form >
            <h1 className="ui centered">Get Started</h1>
            <div className="explain">
              This wizard steps through applying the Dallas 2.0
              classification for a given case and data.
            </div>
            <div>&nbsp;</div>
            <div className="explain">
              Although we never store the data processed
              and it is never transmitted to a server,
              we strongly advise against inputing patient 
              identifying information.
            </div>
            <div>&nbsp;</div>
            <div className="explain">
              Use the identifier below to track a result
              back to a particular run.  At the end, we 
              will show a result with your name, the date, 
              the identifier and the results.
            </div>
            <div>&nbsp;</div>
            <Form.Field>
              <label>Identifier</label>
              <input
              placeholder='Do not put patient name!'
              className="textinput"
              onChange={this.props.handleChange('identifier')}
              defaultValue={values.identifier}
              />
            </Form.Field>
            <div>&nbsp;</div>
           
            <Button className="bottombutton" onClick={this.saveAndContinue}>Start Mapping</Button>
            
          </Form>
          
        )
    }
}
export default Step1