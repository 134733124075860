import React from 'react';

import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';

import Colors from './Colors.js'
import * as d3 from 'd3'

class ScoreChart extends React.Component {

    constructor(props){
        super(props)
        this.createChart = this.createChart.bind(this)
    }
    componentDidMount() {
        this.createChart()
    }
    componentDidUpdate() {
        this.createChart()
    }

    createChart() {
        const node = this.node
        const in_width=this.props.size[0] 
        const in_height=this.props.size[1]

        const margin = {top: 10, right: 30, bottom: 30, left: 30};
        const width = in_width - margin.left - margin.right;
        const height= in_height - margin.top - margin.bottom;
        const scores = this.props.scores
        const colors = new Colors();
        
        select("#scorechart")
            .append("div")
            .style("opacity", 0)
            .attr("id", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px")

        const xAxis = scaleLinear()
            .domain([0,150])
            .range([ 0, width ]);
        select(node)
            .append("g")
            .attr("transform", "translate("+margin.left+","+ (height+margin.top) + ")")
            .call(axisBottom(xAxis));

        const yAxis= scaleLinear()
            .domain([0, 350])
            .range([ height, 0]);
        select(node)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(axisLeft(yAxis));

        select(node)  // Orange horizontal line at 20
            .append("line")
            .attr("x1",xAxis(0))
            .attr("x2",xAxis(150))
            .attr("y1",yAxis(30))
            .attr("y2",yAxis(30))
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .style("stroke", "orange")

        select(node) // Red horizontal line at 80
            .append("line")
            .attr("x1",xAxis(0))
            .attr("x2",xAxis(150))
            .attr("y1",yAxis(80))
            .attr("y2",yAxis(80))
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .style("stroke", "red")

        select(node)  // Blue vertical line at 40
            .append("line")
            .attr("x1",xAxis(40))
            .attr("x2",xAxis(40))
            .attr("y1",yAxis(0))
            .attr("y2",yAxis(350))
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .style("stroke", "blue")

        select(node)  // Blue vertical line at 50
            .append("line")
            .attr("x1",xAxis(50))
            .attr("x2",xAxis(50))
            .attr("y1",yAxis(0))
            .attr("y2",yAxis(350))
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .style("stroke", "blue")

        select(node)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .selectAll('dot')
            .data(scores)
            .enter()
            .append("circle")
                .attr("cx", function(d) { return xAxis(d.dallas2CalculateX());})
                .attr("cy", function(d) { return yAxis(d.dallas2CalculateY());})
                .attr("r", 7)
                .style("fill", function(d) {return colors.getColor(d.getCategory());})
            .on("mouseover", function(d) {
                d3.select('#tooltip')
                   .transition()		
                   .duration(200)		
                   .style("opacity", 1)
                d3.select('#tooltip')
                   .html("Dallas 2.0 ("+d.dallas2CalculateX()+","+d.dallas2CalculateY()+","+d.dallas2CalculateZ()+"):"+ d.dallas2Classification()+ "<br/>Dallas 1.0 ("+d.calculateX()+","+d.calculateY()+"): " + d.dallasClassification()+ "<br/>Chicago 4.0: " + d.chicagoClassification4() + "<br/>Chicago 3.0: " + d.chicagoClassification() + "<br/>Identifider: " + d.getId()+ "<br/>Category: " + d.getCategory())
                   .style("left", (d3.event.pageX) + "px")		
                   .style("top", (d3.event.pageY - 28) + "px");		

            })					
            .on("mouseout", function(d) {		
                d3.select("#tooltip")
                    .transition()		
                    .duration(500)		
                    .style("opacity", .0);	
            })
     }

    render(){
        return <svg ref={node => this.node = node}>
               </svg>
    }
}

export default ScoreChart;