import React from 'react';

import Step1 from './Step1.js'
import Step2 from './Step2.js'
import Step3 from './Step3.js'
import Step4 from './Step4.js'
import Step5 from './Step5.js'
import Step6 from './Step6.js'
import Step7 from './Step7.js'
import Step8 from './Step8.js'
import Step9 from './Step9.js'

import Success from './Success.js'
import Failure from './Failure.js'

class DallasWizard extends React.Component {
     
    // This is a super complex state that represents the whole state of the wizard.  
    // I wanted to separate it into a helper but react doesn't play nicely 
    // with nested state attributes so ... 
    state = {
        step: 1,  // Step 1:  Demographics
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        institution: '',
        identifier: '',
        category: '',
        
        // Step 2:  Symptoms
        dysphagia: '', 
        regurgitation: '', 
        chestpain: '',
        weightloss: '',

        // Step 3:  Patient Characteristics
        bmi: 0.0,
        narcotics: false,
        priorheller: false,
        priorpoem: false,
        dorfundoplication: false,
        toupetfundoplication: false,
        nissenfundoplication: false,
        lapband: false,
        gastricsleeve: false,
        gastricbypass: false, 

        // Step 4:  Barium Swallow
        narrowgej: false,
        tabletlodgedatgej: false,
        dilated: 'none',
        sigmoidizationofesophagus: false,
        sumpeffect: false,
        bariumesophagramprimaryperistalsis: 'intact',
        bariumesophagramdelay: false,
        bariumesophagramretrograde: false,
        bariumesophagramcorkscrewesophagus: false,

        // Step 5:  Endoscopy
        hiatalhernia: '',
        hillgradevalve: '',
        egddilated: '',
        egdretention: '',
        appearanceofles: 'opensandcloses',
        resistanceatles: '',
        contractions: 'periodic',

        // Step 6:  HRM
        manometryirp: 0,
        manometrypercentfailed: 0,
        manometrypercentweak: 0,
        manometrypercenthypercontractile: 0,
        manometrypercentpep: 0,
        manometrypercentprematuredl: 0,
        manometrypercentfragmentedswallows: 0,
        manometrypercentdistalpressurization: 0,
        manometrypercentmidpressurization: 0,
        simultaneouscontraction: 0,
        multiplepeakedswallows: false,
        pseudorelaxation: false,
        irpduringuprightswallows: 0,
        manometryabnormalswallows: 'somenormal', 

        // Step 7:  Flip 1
        impedanceplanimetrydi: 0,
        diameter: 0,
        lesnonrelaxingatlowvolume: false,
        poorrelaxation: false,

        // Step 8:  Flip 2 - Contractions
        impedanceplanimetryabsent: false,
        impedanceplanimetryantegradediminished: false,
        impedanceplanimetryrac: false,
        impedanceplanimetryrrc: false,
        impedanceplanimetryddc: false,

        // Step 9:  Flip 3 - Spasms
        impedanceplanimetrysec: false,
        secgt20: false,
        impedanceplanimetrysoc: false,
        socgt20: false,
        impedanceplanimetryhc: false,
        leslift: false,

        // Diagnosis: Only from uploads
        idx: '',
        dxcc3: '',
        dxdc1: '',

        // Score:
        dascore: 0,
        llscore: 0,
        asscore: 0
    }

    constructor() {
      super();
      this.handleChange = this.handleChange.bind(this);
      this.baseState = this.state;
    }
    
    nextStep = () => {
        const { step } = this.state
        this.setState({
            step : step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        })
    }

    startOver = () => {
        this.setState(this.baseState)
    }
    
    handleChange = input => event => {
        this.setState({ [input] : event.target.value })
    }

    render() {
        const { step } = this.state;
        const values = this.state;
        
        switch(step) {
        case 1:
          return <Step1
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    values={values}
                    />
        case 2:
            return <Step2
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                    values={values}
                    />
        case 3:
            return <Step3
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}                    
                    handleChange = {this.handleChange}
                    values={values}
                    />
        case 4:
            return <Step4
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}                    
                    handleChange = {this.handleChange}
                    values={values}
                  />
        case 5:
            return <Step5
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}                    
                     handleChange = {this.handleChange}
                     values={values}
                   />
        case 6:
            return <Step6
                             nextStep={this.nextStep}
                             prevStep={this.prevStep}                    
                             handleChange = {this.handleChange}
                             values={values}
                           />
        case 7:
            return <Step7
                             nextStep={this.nextStep}
                             prevStep={this.prevStep}                    
                             handleChange = {this.handleChange}
                             values={values}
                           />
        case 8:
            return <Step8
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}                    
                            handleChange = {this.handleChange}
                            values={values}
                         />                                   
        case 9:
            return <Step9
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}                    
                            handleChange = {this.handleChange}
                            values={values}
                        />                     
        case 10:
            return <Success 
                    values={values}
                    prevStep={this.prevStep}                    
                    startOver={this.startOver}
                  />
        default:
            return <Failure /> 
          }
    }
}

export default DallasWizard;