import React, {  Component } from 'react';
import { Grid, GridRow, GridColumn, Button } from 'semantic-ui-react';

import Score from './score.js'
import ScoreChart from './ScoreChart.js'


class Success extends Component{

    goBack = (e) => {
        e.preventDefault()
        this.props.prevStep()
    }
      
    startOver = (e) => {
        e.preventDefault()
        this.props.startOver()
    }

    render(){
        const { values } = this.props;
        const score = new Score(values);
        const thedate = new Date().toLocaleString() //toJSON().substring(0,19).replace('T',' ');

        // console.log(values)
        return(
            <div>
                <div className="explain">
                    <span className="bold">Dallas Coordinates 2.0 ( {score.dallas2CalculateX()}, {score.dallas2CalculateY()}, {score.dallas2CalculateZ()} )</span>
                </div>
                
                <div className="explain">
                    <span>{thedate}</span> - 
                    <span>{values.identifier}</span> 
                </div>

                <Grid className="equal width">
                    <GridRow>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                        D
                    </GridColumn>
                    <GridColumn>
                        A
                    </GridColumn>
                    <GridColumn>
                        L
                    </GridColumn>
                    <GridColumn>
                        L
                    </GridColumn>
                    <GridColumn>
                        A
                    </GridColumn>
                    <GridColumn>
                        S
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    </GridRow>

                    <GridRow>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                        2.0
                    </GridColumn>
                    <GridColumn>
                        {score.dallas2CalculateD()}
                    </GridColumn>
                    <GridColumn>
                        {score.dallas2CalculateA1()}
                    </GridColumn>  
                     <GridColumn>
                        {score.dallas2CalculateL1()}
                    </GridColumn>
                    <GridColumn>
                        {score.dallas2CalculateL2()}
                    </GridColumn>
                    <GridColumn>
                        {score.dallas2CalculateA2()}
                    </GridColumn>
                    <GridColumn>
                        {score.dallas2CalculateS()}
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    </GridRow>
                    <GridRow>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                        1.0
                    </GridColumn>
                    <GridColumn>
                        {score.calculateD()}
                    </GridColumn>
                    <GridColumn>
                        {score.calculateA1()}
                    </GridColumn>  
                     <GridColumn>
                        {score.calculateL1()}
                    </GridColumn>
                    <GridColumn>
                        {score.calculateL2()}
                    </GridColumn>
                    <GridColumn>
                        {score.calculateA2()}
                    </GridColumn>
                    <GridColumn>
                        {score.calculateS()}
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    <GridColumn>
                    </GridColumn>
                    </GridRow>
                </Grid>
                <div>&nbsp;</div>
                <div id="scorechart">
                    <ScoreChart
                    scores = { [score] }
                    size={[400,300]}
                    />
                </div>
                
                <div>
                    <span>Dallas 2.0 Coordinates: {score.dallas2Classification()}</span>
                </div>
                <div>
                    <span>Dallas 1.0 Coordinates ({score.calculateX()}, {score.calculateY()}): {score.dallasClassification()}</span>
                </div>

                <div>
                    <span>Chicago Classification 4.0: {score.chicagoClassification4()}</span>
                </div>
                <div>
                    <span>Chicago Classification 3.0: {score.chicagoClassification()}</span>
                </div>
                <Button onClick={() => window.print()}>Print</Button>
                
                <Button onClick={this.goBack}>Go Back</Button>
                <Button onClick={this.startOver}>Go To The Beginning</Button>
            </div>
        )
    }
}

export default Success;