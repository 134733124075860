import React from 'react'
import logo from './esophaguidelogo.png'
import { Tab } from 'semantic-ui-react'
import DallasWizard from './components/DallasWizard.js'
import UploadToScore from './components/UploadToScore.js'
import './App.css'

import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './components/login'
import LogoutButton from './components/logout'
import Profile from './components/profile'

function App() {
  const { isAuthenticated } = useAuth0();
  const panes = [
    { menuItem: 'Wizard', pane: <Tab.Pane key="wizard"><DallasWizard/></Tab.Pane>},
    { menuItem: 'Upload', pane: <Tab.Pane key="upload"><UploadToScore/></Tab.Pane>},
    { menuItem: 'About', pane: <Tab.Pane key="about"> <div><h3>Developed by Esophaguide.</h3><span>Contact us at info@esophaguide.com</span></div></Tab.Pane>},
  ]

  if (isAuthenticated === true){
    
    return (

      <div className="App">
      <header className="App-header">
        <div>
          <Profile/>&nbsp;|&nbsp;<LogoutButton />
        </div>
        <div>&nbsp;</div>
        <a
          className="App-link"
          href="https://esophaguide.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Esophaguide.com
        </a>
        <img src={logo} className="App-logo" alt="logo" />
      
        <Tab panes={panes} 
             renderActiveOnly={false} />
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://esophaguide.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Esophaguide.com
        </a>
        <img src={logo} className="App-logo" alt="logo" />
        <div>
        <div className="homeexplain">
          Click Login below and use your provided dallas.esophaguide.com
          user and password to access this site.
        </div>
        <div>&nbsp;</div>
        <div className="homeexplain">
          If you do not have
          a login and would like one, please contact us at info@esophaguide.com.
        </div>
        <div>&nbsp;</div>
        </div>
        <div>
        <LoginButton />
        </div>
        <div>
        <div>&nbsp;</div>
        <div className="homeexplain">
            The DALLAS.esophaguide.com is a research tool under the principal investigator 
            Vani Konda and created by KAT Data Labs. This tool is intended to supplement 
            communication about clinical decision making regarding diagnosis of esophageal 
            motility. 
        </div>
        <div>&nbsp;</div>
        <div className="homeexplain">
            This tool is not intended for use to replace the clinical decision making of a physician.
        </div>
        </div>
        </header>
      </div>

    );
  }
}

export default App;
