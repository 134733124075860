class Score {
 
    constructor(values){
        this.values = values;
        console.log(values)
    }

    getCategory(){
        let d = this.values["category"]
        if (typeof d === 'undefined'){
            d = "Unknown";
        } else {
            d = d.toLowerCase()
        }
        return d   
    }

    getId(){
        let d = this.values["identifier"]
        if (typeof d === 'undefined'){
            d = "Unknown";
        }
        return d   
    }

    isOn(fieldName){
        if (this.values[fieldName] === null){
            return false;
        }
        if (this.values[fieldName] === true ||
            this.values[fieldName] === "true" || 
            this.values[fieldName] === "on" || 
            (""+this.values[fieldName]).trim().toLowerCase() === "true"){
                return true;
        } else {
            return false;
        }
    }

    getNumeric(fieldName, fallback){
        let n = fallback
        if (this.values[fieldName] !== null){
            let s = this.values[fieldName]
            s = Number(s)
            if (s !== "NaN"){
                n = s
            }   
        }
        return n
    }

    // Dallas 2.0 Methods

    dallas2CalculateX(){
        let score = 0;
        score += this.dallas2CalculateL1();  // 130
        score += this.dallas2CalculateL2(); //   30
        if (score > 160){
            score = 160;
        }
        return score;
    }

    dallas2CalculateY(){
        let score = 0;
        score += this.dallas2CalculateA2();  // 
        score += this.dallas2CalculateS();  // 
        if (score > 350){
            score = 350;
        }
        return score;
    }

    dallas2CalculateZ(){
        let score = 0;
        score += this.dallas2CalculateA1();  
        score += this.dallas2CalculateD(); 
        if (score > 100){
            score = 100;
        }
        return score;
    }

    dallas2PainModifier(){
        let score = 0;
        let cp = 0;
        let dy = 0;
        if (this.values["chestpain"] === "none"){
            cp = 0 
        } else if (this.values["chestpain"] === "occassional"){
            cp = 1
        } else if (this.values["chestpain"] === "daily"){
            cp = 2
        } else if (this.values["chestpain"] === "multipleperday"){
            cp = 3
        }
        if (this.values["dysphagia"] === "none"){
            dy = 0 
        } else if (this.values["dysphagia"] === "occassional"){
            dy = 1
        } else if (this.values["dysphagia"] === "daily"){
            dy = 2
        } else if (this.values["dysphagia"] === "every"){
            dy = 3
        }

        score = Math.round(8 * cp / dy);
        return score;
    }

    // Dysfunction D - (Step 2)
    dallas2CalculateD(){
        let score = 0;
        let localScore = 0;
        if (this.values["dysphagia"] === "none"){
            localScore += 0 
        } else if (this.values["dysphagia"] === "occassional"){
            localScore += 1
        } else if (this.values["dysphagia"] === "daily"){
            localScore += 2
        } else if (this.values["dysphagia"] === "every"){
            localScore += 3
        }

        if (this.values["regurgitation"] === "none"){
            localScore += 0 
        } else if (this.values["regurgitation"] === "occassional"){
            localScore += 1
        } else if (this.values["regurgitation"] === "daily"){
            localScore += 2
        } else if (this.values["regurgitation"] === "every"){
            localScore += 3
        }

        if (this.values["chestpain"] === "none"){
            localScore += 0 
        } else if (this.values["chestpain"] === "occassional"){
            localScore += 1
        } else if (this.values["chestpain"] === "daily"){
            localScore += 2
        } else if (this.values["chestpain"] === "multipleperday"){
            localScore += 3
        }

        if (this.values["weightloss"] === "none"){
            localScore += 0 
        } else if (this.values["weightloss"] === "to11"){
            localScore += 1
        } else if (this.values["weightloss"] === "12to22"){
            localScore += 2
        } else if (this.values["weightloss"] === "over22"){
            localScore += 3
        }
        score += localScore;

        score += this.dallas2PainModifier()

        if (score > 20){
            score = 20;
        }
        return score;
    }

    // Anatomic and Patient Considerations 
    dallas2CalculateA1(){
        let score = 0;
        let localScore = 0;
        if (this.getNumeric("bmi", 0) >= 35){
            localScore += 5;
        }
        if (this.isOn("narcotics")){
            localScore += 5
        }
        if (this.isOn("priorheller")){
            localScore += 2
        }
        if (this.isOn("priorpoem")){
            localScore += 2
        }
        if (this.isOn("dorfundoplication")){
            localScore += 2
        }
        if (this.isOn("toupetfundoplication")){
            localScore += 2
        }
        if (this.isOn("nissenfundoplication")){
            localScore += 10
        }
        if (this.isOn("lapband")){
            localScore += 20
        }
        if (this.isOn("gastricsleeve")){
            localScore += 20
        }
        if (this.isOn("gastricbypass")){
            localScore += 10
        }
        if (this.isOn("sigmoidizationofesophagus") 
         || this.isOn("sumpeffect")){
            localScore += 5
        }
        if (this.values["hiatalhernia"] === "severe"){
            localScore += 5 
        } else if (this.values["hiatalhernia"] === "moderate"){
            localScore += 5
        } else if (this.values["hiatalhernia"] === "mild"){
            localScore += 2
        } // None is 0        
        
        score = localScore;
        return score;
    }

    // This method calculates the score for LL (LES Pressure / LES Dynamic)
    dallas2CalculateL1(){
        let score = 0;
        let localScore = 0;

        // Barium Esophagram Score - Max 16
        if (this.values["dilated"] === "severe"){
            localScore += 6 
        } else if (this.values["dilated"] === "moderate"){
            localScore += 4
        } else if (this.values["dilated"] === "mild"){
            localScore += 2
        } // None is 0

        if (this.isOn("narrowgej")){
            localScore += 4
        }
        if (this.isOn("tabletlodgedatgej")){
            localScore += 4
        }
        if (this.isOn("bariumesophagramdelay")){
            localScore += 2
        }

        if (localScore > 16){
            score = 16;
        } else {
            score = localScore;
        }

        // Endoscopy Score - Max Score 12
        localScore = 0

        if (this.values["resistanceatles"] === "none"){
            localScore += 0 
        } else if (this.values["resistanceatles"] === "mild"){
            localScore += 1
        } else if (this.values["resistanceatles"] === "moderate"){
            localScore += 2
        } else if (this.values["resistanceatles"] === "severe"){
            localScore += 3
        }

        if (this.values["appearanceofles"] === "patulous"){
            localScore += 0 
        } else if (this.values["appearanceofles"] === "opensandcloses"){
            localScore += 1
        } else if (this.values["appearanceofles"] === "minimallyopens"){
            localScore += 2
        } else if (this.values["appearanceofles"] === "tight"){
            localScore += 3
        }

        if (this.values['egddilated'] === 'severe'){
            localScore += 3;
        } else if (this.values['egddilated'] === 'moderate'){
            localScore += 2;
        } else if (this.values['egddilated'] === 'mild'){
            localScore += 1;
        } // None 0

        if (this.values['egdretention'] === 'severe'){
            localScore += 3;
        } else if (this.values['egdretention'] === 'moderate'){
            localScore += 2;
        } else if (this.values['egdretention'] === 'mild'){
            localScore += 1;
        } // None 0 

        if (localScore > 12){
            score += 12;
        } else {
            score += localScore;
        }

        // Mamometry - Score - Max 50
        localScore = 0;
        if (this.getNumeric("manometryirp", 0) > 15){
            localScore += 30;
        }else if (this.getNumeric("manometryirp", 0) >= 10){
            localScore += 10;
        }

        if (this.getNumeric("manometrypercentdistalpressurization", 0) >= 20){
            localScore += 20;
        }

        if (localScore > 50){
            score += 50;
        } else {
            score += localScore;
        }

        // Impedance Planimetry - Score - Max 50
        localScore = 0;
        if (this.isOn("lesnonrelaxingatlowvolume") 
         || this.isOn("poorrelaxation")){
            localScore += 10;
        }
        
        // Expect 0-30.  < 3 is bad.
        if (this.getNumeric("impedanceplanimetrydi", 0) <= 2 
         && this.getNumeric("impedanceplanimetrydi", 0) >= 0){
            localScore += 20;
        }
        if (this.getNumeric("impedanceplanimetrydi", 0) <= 3.0 
         && this.getNumeric("impedanceplanimetrydi", 0) > 2){
            localScore += 10;
        }
        if (this.getNumeric("impedanceplanimetrydi", 0) > 3.0){
           localScore += 0;
        }
        // Diameter
        if (this.getNumeric("diameter", 0) <= 12 
         && this.getNumeric("diameter", 0) >= 0){
            localScore += 20;
        }
        if (this.getNumeric("diameter", 0) <= 14.0 
         && this.getNumeric("diameter", 0) > 12){
            localScore += 10;
        }
        if (this.getNumeric("diameter", 0) > 14.0){
            localScore += 0;
        }

        if (localScore > 50){
            score += 50;
        }else {
            score += localScore;
        }

        if (score > 130){
            score = 130;
        }
        return score;
    }
    
    dallas2CalculateL2(){
        let score = 0;
        let localScore = 0;
        if (this.isOn("pseudorelaxation")){
            localScore += 10;
        }
        if (this.isOn("leslift")){
            localScore += 20;
        }
        // Max 30
        if (localScore >= 30){
            score += 30;
        } else {
            score += localScore;
        }
        return score;
    }

    // This method calculates the score from the A S part of Dallas
    dallas2CalculateA2(){
        let score = 0;
        let localScore = 0;

        if (this.values["bariumesophagramprimaryperistalsis"] === "absent"){
            localScore += 0 
        } else if (this.values["bariumesophagramprimaryperistalsis"] === "weak"){
            localScore += 2
        } else if (this.values["bariumesophagramprimaryperistalsis"] === "intact"){
            localScore += 4
        } else if (this.values["bariumesophagramprimaryperistalsis"] === "disordered"){
            localScore += 6
        }
        score += localScore;

        // Endoscopy has no scoring in A2

        // Manometry Score ... 
        localScore = 0;
        if (this.getNumeric("manometrypercentfailed", 0) >= 100){
            localScore = 0;
        }else if (this.getNumeric("manometrypercenthypercontractile", 0) >= 20){
            localScore = 60;
        }else if (this.getNumeric("manometrypercenthypercontractile", 0) > 0){
            localScore = 30;
        }else if (this.getNumeric("manometrypercentweak", 0)
                 +this.getNumeric("manometrypercentfailed", 0) >= 70){
            localScore = 10;
        }else if (this.getNumeric("manometrypercentfailed", 0) >= 50){
            localScore = 10;
        }else{
            localScore = 20;  // TODO:  Figure out if this is correct.
        }
        if (localScore >= 120){
            score += 120;
        } else {
            score += localScore;
        }

        // Impedance Planometry Score - Max 20
        localScore = 0;
        if (this.isOn("impedanceplanimetryrac")){
            localScore += 15;
        } else if (this.isOn("impedanceplanimetryantegradediminished")){
            localScore += 10;
        }
        if (this.isOn("impedanceplanimetryhc")){
            localScore += 20;
        }
        if (localScore >= 20){
            score += 20;
        } else {
            score += localScore;
        }

        if (score > 126){
            score = 126;
        }
        return score;
    }

    dallas2CalculateS(){
        let score = 0;
        let localScore = 0;

        // Esophagram
        if (this.isOn("bariumesophagramretrograde")){
            localScore += 2
        }
        if (this.isOn("bariumesophagramcorkscrewesophagus")){
            localScore += 12;
        } 
        score += localScore;
        
        // Endoscopy
        localScore = 0;
        if (this.values["contractions"] === "absent"){
            localScore += 0 
        } else if (this.values["contractions"] === "periodic"){
            localScore += 4
        } else if (this.values["contractions"] === "simultaneous"){
            localScore += 8
        } else if (this.values["contractions"] === "spastic"){
            localScore += 10
        } else if (this.values["contractions"] === "buttressing"){
            localScore += 12
        }
        score += localScore;

        // Manometry - Spasms/Pressure
        localScore = 0;
        if (this.getNumeric("manometrypercentprematuredl", 0) >= 20){
            localScore = 60;
        }
        if ((this.getNumeric("manometrypercentmidpressurization", 0) >= 20) 
         || (this.getNumeric("simultaneouscontraction", 0) >= 20)) {
            localScore += 10;
        }       

        if (this.isOn("multiplepeakedswallows")){
            localScore += 10;
        }
        if (this.values["manometryabnormalswallows"] === "abnormal"){
            localScore += 40;
        } else if (this.values["manometryabnormalswallows"] === "somenormal"){
            localScore += 0;  // Descored 2/15/2021
        }
        if (this.getNumeric("manometrypercentpep", 0) >= 20){
            localScore += 10;
        }

        if (localScore >= 130){
            score += 130;
        } else {
            score += localScore;
        }

        // Impedance Planimetry 
        localScore = 0;
        if (this.isOn("impedanceplanimetryddc")){
            localScore += 10;
        } 
        if (this.isOn("impedanceplanimetryrrc")){
            localScore += 10;
        } 
        if (this.isOn("impedanceplanimetrysec")){
            localScore += 20;
        } 
        if (this.isOn("impedanceplanimetrysoc")){
            localScore += 40;
        } 
        if (this.isOn("secgt20")){
            localScore += 20;
        } 
        if (this.isOn("socgt20")){
            localScore += 20;
        } 
        if (localScore >= 120){
            score += 120;
        } else {
            score += localScore;
        }

        if (score > 224){
            score = 224;
        }
        return score;
    }

    dallas2Classification(){
        let classification = ""
        const x = this.dallas2CalculateX()
        const y = this.dallas2CalculateY()
        
        if (y === 0){  // Y steady  Lower left and right
            if (x >= 0 && x < 40){
                classification = "Disorders on the Limited Spectrum (Absent Contractility)"
            } else if (x > 50 ){
                classification = "Disorders on the Achalasia Spectrum (Classic Achalasia)"
            } else if (x >= 40 && x <= 50){
                classification = "Disorders on the Limited or Achalasia Spectrum (Absent Contractility or Classic Achalasia)"
            }
        }
    
        // Lower right
        if (x >= 40 && y > 0 && y <= 30){  
            classification = "Disorders on the Achalasia Spectrum (Suspect Achalasia with Compartmentalization)"
        }

        // Midle Right
        if (x >= 50 && y > 30 && y < 80){
            classification = "Disorders on the Achalasia Spectrum (EGJ Outflow Obstruction)"
        }
        if (x >= 40 && x < 50 && y > 30 && y < 80){
            classification = "EGJ Outflow Obstruction (Without Evidence of Disordered Peristalsis)"
        }
        
        // Upper right
        if (x >= 40 && y >= 80){ 
            classification = "Disorders on the Spastic Achalasia Spectrum (Consider Type III Achalasia, EGJ Outflow Obstruction with Spastic Features, Hypercontractile Features or Spastic Variant Features)"
        }

        // Lower left
        if (x >= 0 && x < 40 && y > 0 && y <= 50 ){
            classification = "Unremarkable or Limited (Consider Normal, Ineffective Esophageal Motility)"
        }

        // Middle left
        if (x >= 0 && x < 40 && y > 50 && y < 80 ){
            classification = "Unremarkable (Consider Normal)"
        }

        // Upper left
        if (x >= 0 && x < 40 && y >= 80 && y <= 350 ){
            if (this.calculateA2() > 50){
                classification = "Discorders on the Spastic Spectrum (Hypercontractile)"
            } else {
                classification = "Disorders on the Spastic Spectrum (Consider Diffuse Esophageal Spasm or Symptomatic Spastic Variant)"
            }
        }

        return classification
    }

    // Dallas 1.0 ----------------------------------------------------------------------

    calculateX(){
        let score = 0;
        score += this.calculateL1();  // 100
        if (score > 100){
            score = 100;
        }
        return score;
    }

    calculateY(){
        let score = 0;
        score += this.calculateL2(); // 20
        score += this.calculateA2();  // 80
        score += this.calculateS();  // 200
        if (score > 300){
            score = 300;
        }
        return score;
    }

    calculateD(){
        return 0;
    }

    calculateA1(){
        return 0;
    }
    
    // This method calculates the score for LL (LES Pressure / LES Dynamic)
    calculateL1(){
        let score = 0;
        let localScore = 0;

        // Barium Esophagram Score - Max 10
        if (this.isOn("narrowgej")){
            localScore += 10
        }
        if (this.isOn("tabletlodgedatgej")){
            localScore += 5
        }
        if (this.values["dilated"] === "severe"){
            localScore += 10 
        } else if (this.values["dilated"] === "moderate"){
            localScore += 10
        } else if (this.values["dilated"] === "mild"){
            localScore += 5
        }
        if (localScore > 10){
            score = 10;
        } else {
            score = localScore;
        }

        // Endoscopy Score - Max Score 10
        localScore = 0
        if (this.values["resistanceatles"] === "none"){
            localScore += 0 
        } else if (this.values["resistanceatles"] === "mild"){
            localScore += 5
        } else if (this.values["resistanceatles"] === "moderate"){
            localScore += 10
        } else if (this.values["resistanceatles"] === "severe"){
            localScore += 10
        }

        if (this.values['egddilated'] === 'severe'){
            localScore += 10;
        } else if (this.values['egddilated'] === 'moderate'){
            localScore += 10;
        } else if (this.values['egddilated'] === 'mild'){
            localScore += 5;
        }
        if (this.values['egdretention'] === 'severe'){
            localScore += 10;
        } else if (this.values['egdretention'] === 'moderate'){
            localScore += 10;
        } else if (this.values['egdretention'] === 'mild'){
            localScore += 5;
        }
        if (localScore > 10){
            score += 10;
        } else {
            score += localScore;
        }

        // Mamometry - Score - Max 40
        localScore = 0;
        if (this.getNumeric("manometryirp", 0) > 15){
            localScore += 40;
        }else if (this.getNumeric("manometryirp", 0) >= 10){
            localScore += 10;
        }
        if (localScore > 40){
            score += 40;
        } else {
            score += localScore;
        }

        // Impedance Planimetry - Score - Max 40
        localScore = 0;
        if (this.isOn("lesnonrelaxingatlowvolume")){
            localScore += 10;
        }
        if (this.isOn("poorrelaxation")){
            localScore += 10;
        }
        // Expect 0-30.  < 2.8 is bad.
        if (this.getNumeric("impedanceplanimetrydi", 0) < 2.8 
         && this.getNumeric("impedanceplanimetrydi", 0) > 0){
            localScore += 40;
        }
        if (localScore > 40){
            score += 40;
        }else {
            score += localScore;
        }

        if (score > 100){
            score = 100;
        }
        return score;
    }
    
    calculateL2(){
        let score = 0;
        let localScore = 0;
        if (this.isOn("pseudorelaxation")){
            localScore += 10;
        }
        if (this.isOn("leslift")){
            localScore += 20;
        }
        if (localScore >= 20){
            score += 20;
        } else {
            score += localScore;
        }
        // Max 20
        return score;
    }

    // This method calculates the score from the A S part of Dallas
    calculateA2(){
        let score = 0;
        let localScore = 0;

        // A
        // Manometry Score ... 
        localScore = 0;
        if (this.getNumeric("manometrypercentfailed", 0) >= 100){
            localScore = 0;
        }else if (this.getNumeric("manometrypercenthypercontractile", 0) >= 20){
            localScore = 60;
        }else if (this.getNumeric("manometrypercenthypercontractile", 0) > 0){
            localScore = 30;
        }else if (this.getNumeric("manometrypercentweak", 0)
                 +this.getNumeric("manometrypercentfailed", 0) >= 50){
            localScore = 10;
        }else{
            localScore = 20;
        }
        if (localScore >= 60){
            score += 60;
        } else {
            score += localScore;
        }

        // Impedance Planometry Score - Max 20
        localScore = 0;
        if (this.isOn("impedanceplanimetryrac")){
            localScore += 15;
        } else if (this.isOn("impedanceplanimetryantegradediminished")){
            localScore += 10;
        }
        if (this.isOn("impedanceplanimetryhc")){
            localScore += 20;
        }
        if (localScore >= 20){
            score += 20;
        } else {
            score += localScore;
        }

        if (score > 80){
            score = 80;
        }
        return score;
    }

    calculateS(){
        let score = 0;
        let localScore = 0;

        // Manometry - Spasms/Pressure
        localScore = 0;
        if (this.getNumeric("manometrypercentprematuredl", 0) >= 20){
            localScore = 40;
        }
        if (this.getNumeric("manometrypercentmidpressurization", 0) >= 20){
            localScore += 10;
        }
        if (this.getNumeric("manometrypercentpep", 0) >= 20){
            localScore += 10;
        }
        if (this.getNumeric("simultaneouscontraction", 0) >= 20){
            localScore += 10;
        }
        if (this.isOn("multiplepeakedswallows")){
            localScore += 10;
        }
        if (this.values["manometryabnormalswallows"] === "abnormal"){
            localScore += 40;
        } else if (this.values["manometryabnormalswallows"] === "somenormal"){
            localScore += 20;
        }

        if (localScore >= 80){
            score += 80;
        } else {
            score += localScore;
        }

        // Impedance Planimetry 
        localScore = 0;
        if (this.isOn("impedanceplanimetryddc")){
            localScore += 10;
        } 
        if (this.isOn("impedanceplanimetryrrc")){
            localScore += 10;
        } 
        if (this.isOn("impedanceplanimetrysec")){
            localScore += 20;
        } 
        if (this.isOn("impedanceplanimetrysoc")){
            localScore += 40;
        } 
        if (this.isOn("secgt20")){
            localScore += 20;
        } 
        if (this.isOn("socgt20")){
            localScore += 20;
        } 
        if (localScore >= 120){
            score += 120;
        } else {
            score += localScore;
        }

        if (score > 200){
            score = 200;
        }
        return score;
    }

    dallasClassification(){
        let classification = ""
        const x = this.calculateX()
        const y = this.calculateY()

        if (y === 0){  // Y steady  Lower left and right
            if (x >= 0 && x < 40){
                classification = "Absent Contractility"
            } else if (x > 40 ){
                classification = "Type I Achalasia"
            } else if (x === 40){
                classification = "Absent Contractility or Type I Achalasia"
            }
        }
    
        // Lower right
        if (x >= 40 && y > 0 && y <= 30){  
            classification = "Type II Achalasia"
        }

        // Midle Right
        if (x >= 50 && y > 30 && y < 80){
            classification = "EGJ Outflow Obstruction"
        }
        if (x >= 40 && x < 50 && y > 30 && y < 80){
            classification = "EGJ Outflow Obstruction NOS"
        }
        
        // Upper right
        if (x >= 40 && y >= 80){ 
            classification = "Consider Type III Achalasia or Spastic Achalasia Variant"
        }

        // Lower left
        if (x >= 0 && x < 40 && y > 0 && y <= 50 ){
            classification = "Consider Normal, Ineffective Esophageal Motility, or Fragmented Peristalsis"
        }

        // Middle left
        if (x >= 0 && x < 40 && y > 50 && y < 80 ){
            classification = "Consider Normal or Fragmented Peristalsis"
        }

        // Upper left
        if (x >= 0 && x < 40 && y >= 80 && y <= 300 ){
            if (this.calculateA2() > 50){
                classification = "Jackhammer"
            } else {
                classification = "Consider Diffuse Esophageal Spasm or Symptomatic Spastic Variant"
            }
        }


        return classification
    }

    // Chicago Classification 3.0 ----------------------------------------------------------------
    chicagoClassification(){
        let classification = ""
        if (this.getNumeric("manometryirp",0) > 15){  
            classification = "EGJ Outflow Obstruction per Chicago Classification" // Default
            if (this.getNumeric("manometrypercentfailed",0) >= 100 ){
                if (this.getNumeric("manometrypercentpep",0) < 20){
                    classification = "Type I Achalasia"
                } else if (this.getNumeric("manometrypercentpep",0) >= 20){
                    classification = "Type II Achaslasia"
                }
            } else if (this.values["manometryabnormalswallows"] === 'abnormal' 
                 && this.getNumeric("manometrypercentprematuredl", 0) >= 20){
                     classification = "Type III Achalasia"
            }
        } else { // IRP < 15
            classification = "No Motor Disorder per Chicago Classification" // Default
            if (this.getNumeric("manometrypercentfailed", 0) >= 100){
                classification = "Absent Contractility"
                if (this.getNumeric("manometrypercentpep", 0) >= 100){
                    classification = "Type II Achalasia"
                } else if (this.getNumeric("manometryirp", 0) > 10){
                    classification = "Absent Contractility but cannot exclude Type I Achalasia"
                }
            } else {
                if (this.getNumeric("manometrypercenthypercontractile", 0) >= 20){
                    classification = "Hypercontractile esophagus or jackhammer esophagus"
                } else if (this.getNumeric("manometrypercentprematuredl", 0) >= 20){
                    classification = "Diffuse Esophageal Spasm"
                }
                if ((this.getNumeric("manometrypercentfailed", 0) 
                   + this.getNumeric("manometrypercentweak", 0)) >= 50){
                    classification = "Ineffective Esophageal Motility Disorder"
                }
                if (this.getNumeric("manometrypercentfragmentedswallows", 0) >= 50){
                    classification = "Fragmented Peristalsis"
                }   
            }
        }
        return classification
    }

    // Chicago Classification 4.0 ----------------------------------------------------------------
    chicagoClassification4(){
        let classification = ""
        if (this.getNumeric("manometryirp",0) > 15){  
            classification = "EGJ Outflow Obstruction per Chicago Classification" // Default
            if (this.getNumeric("manometrypercentfailed",0) >= 100 ){
                if (this.getNumeric("manometrypercentpep",0) < 20){
                    classification = "Type I Achalasia"
                } else if (this.getNumeric("manometrypercentpep",0) >= 20){
                    classification = "Type II Achaslasia"
                }
            } else if (this.values["manometryabnormalswallows"] === 'abnormal' 
                 && this.getNumeric("manometrypercentprematuredl", 0) >= 20){
                     classification = "Type III Achalasia"
            } else if (this.getNumeric("manometrypercentdistalpressurization",0) < 20 ){
                classification = "Not meeting criteria for meeting EGJ Outflow due to insufficient intrabolous pressurization (Check distal pressurization)"
            
                // Note:  All below assume IRP > 15 and DP >= 20
            } else if (this.getNumeric("manometrypercentprematuredl",0) >= 20){
                classification = "EGJ Outflow Obstruction with Spastic Features"
            } else if (this.getNumeric("manometrypercenthypercontractile",0) >= 20){
                classification = "EGJ Outflow Obstruction with Hypercontractile Features"
            } else if (this.getNumeric("manometrypercentfailed",0) >= 50){
                classification = "EGJ Outflow Obstruction with Ineffective Motility"
            } else if (this.getNumeric("manometrypercentfailed",0) + 
                       this.getNumeric("manometrypercentweak",0) +
                       this.getNumeric("manometrypercentfragmentedswallows",0) >= 70){
                classification = "EGJ Outflow Obstruction with Ineffective Motility"
            } 

        } else { // IRP < 15
            classification = "No Motor Disorder per Chicago Classification" // Default
            if (this.getNumeric("manometrypercentfailed", 0) >= 100){
                classification = "Absent Contractility"
                if (this.getNumeric("manometrypercentpep", 0) >= 100){
                    classification = "Type II Achalasia"
                } else if (this.getNumeric("manometryirp", 0) > 10){
                    classification = "Absent Contractility but cannot exclude Type I Achalasia"
                }
            } else {
                if (this.getNumeric("manometrypercenthypercontractile", 0) >= 20){
                    classification = "Hypercontractile Eesophagus"
                } else if (this.getNumeric("manometrypercentprematuredl", 0) >= 20){
                    classification = "Diffuse Esophageal Spasm"
                }
                if (this.getNumeric("manometrypercentfailed", 0) >= 50){
                    classification = "Ineffective Esophageal Motility Disorder"
                }
                if ((this.getNumeric("manometrypercentfailed", 0) 
                   + this.getNumeric("manometrypercentweak", 0)
                   + (this.getNumeric("manometrypercentfragmentedswallows", 0))) >= 70){
                    classification = "Ineffective Esophageal Motility Disorder"
                }   
            }
       }
        return classification
    }

}
export default Score